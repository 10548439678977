import { useApolloClient, useQuery } from '@apollo/client';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/outline';
import { DocumentTextIcon, MicrophoneIcon } from '@heroicons-v2/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import insightsEmptyImage from '../assets/empty-states/insights.png';
import projectsEmptyImage from '../assets/empty-states/projects.png';
import {
  EmptyState,
  EmptyStateSecondaryText,
  EmptyStateText,
  EmptyStateTitle,
  HeroImage,
} from '../Components/EmptyState2';
import { HomeTrialBanner } from '../Components/HomeTrialBanner';
import InsightsCardView from '../Components/InsightsCardView';
import ListWithFolders from '../Components/ListWithFolders';
import { TListItem } from '../Components/ListWithFolders/ListItem';
import Loader from '../Components/Loader';
import { NewProjectButton } from '../Components/NewProjectButton';
import { ProjectsList } from '../Components/ProjectsList';
import SecondaryToolbar from '../Components/SecondaryToolbar';
import { FETCH_LIGHT_DOCUMENT, FETCH_RECENT_DASHBOARDS } from '../GraphQL/queries';
import { getDashboards } from '../GraphQL/__generated__/getDashboards';
import {
  useCreateLightDocument,
  useDeleteLightDocument,
  useFetchLightDocuments,
} from '../Hooks/useDocuments';
import {
  useFetchAudioRecordings,
  useCreateAudioRecording,
  useDeleteAudioRecording,
} from '../Hooks/useRecordings';
import { useFetchRecentInsights } from '../Hooks/useInsights';
import { useOrganization } from '../Hooks/useOrganization';
import usePermissions from '../Hooks/usePermissions';
import useUsers from '../Hooks/useUsers';
import { Icon24 } from '../Icons/Icon';
import { breakpoint } from '../Shared';

const InsightsEmptyStateLink = styled.a`
  color: var(--brand-purple, #3b2651);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;
`;

const Container = styled.div`
  width: 100%;
  padding: 0 40px;
  ${breakpoint.Tablet`
    padding: 0 20px;
  `}
`;

export function Home(): JSX.Element {
  const dashboardsQuery = useQuery<getDashboards>(FETCH_RECENT_DASHBOARDS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const recentInsightsQuery = useFetchRecentInsights();
  const [, org] = useOrganization();
  const [loadingLightDocs, lightDocs] = useFetchLightDocuments();
  const [loadingAudioRecordings, audioRecordings] = useFetchAudioRecordings();
  const [lightDocsItems, setLightDocsItems] = useState<TListItem[]>([]);
  const [audioRecordingItems, setAudioRecordingItems] = useState<TListItem[]>([]);
  const history = useHistory();
  const { canEditDocuments } = usePermissions();
  const [createLightDocument] = useCreateLightDocument();
  const [deleteLightDocument] = useDeleteLightDocument();
  const [createAudioRecording] = useCreateAudioRecording();
  const [deleteAudioRecording] = useDeleteAudioRecording();
  const client = useApolloClient();

  const dashboards = dashboardsQuery.data?.dashboards || [];
  const hasDashboards = dashboards.length > 0;
  const hasInsights = (recentInsightsQuery.data || []).length > 0;

  useEffect(() => {
    if (!loadingLightDocs) {
      setLightDocsItems(
        lightDocs.map((doc) => ({
          ...doc,
          itemType: 'document',
          name: doc.name || 'Untitled',
          user: { name: doc.userByCreatedBy.name, picture: doc.userByCreatedBy.picture },
          icon: { element: <DocumentTextIcon className="mr-3 h-5 w-5" /> },
          actions: canEditDocuments
            ? [
                {
                  icon: <DocumentDuplicateIcon className="mr-3 h-5 w-5" />,
                  name: 'Copy',
                  handler: async () => {
                    const {
                      data: { lightDocument },
                    } = await client.query({
                      query: FETCH_LIGHT_DOCUMENT,
                      variables: { id: doc.id },
                    });

                    const newDoc = await createLightDocument({
                      content: lightDocument.content,
                      name: lightDocument.name + ' copy',
                      participantId: lightDocument.participantId,
                      createdBy: lightDocument.createdBy,
                    });
                    history.push(`/notes/${newDoc.id}`);
                  },
                },
                {
                  icon: <TrashIcon className="mr-3 h-5 w-5" />,
                  name: 'Delete',
                  handler: async () => {
                    if (
                      confirm(
                        `Are you sure you want to delete "${doc.name}"?. This cannot be undone.`
                      )
                    ) {
                      deleteLightDocument(doc.id);
                    }
                  },
                },
              ]
            : [],
        }))
      );
    }
  }, [loadingLightDocs, lightDocs]);

  useEffect(() => {
    if (!loadingAudioRecordings) {
      setAudioRecordingItems(
        audioRecordings.map((recording) => ({
          ...recording,
          itemType: 'recording',
          name: recording.name || 'Untitled',
          user: {
            name: recording.userByCreatedBy.name,
            picture: recording.userByCreatedBy.picture,
          },
          icon: { element: <MicrophoneIcon className="mr-3 h-5 w-5" /> },
          actions: canEditDocuments
            ? [
                // {
                //   icon: <DocumentDuplicateIcon className="mr-3 h-5 w-5" />,
                //   name: 'Copy',
                //   handler: async () => {
                //     const {
                //       data: { lightDocument },
                //     } = await client.query({
                //       query: FETCH_LIGHT_DOCUMENT,
                //       variables: { id: doc.id },
                //     });

                //     const newDoc = await createLightDocument({
                //       content: lightDocument.content,
                //       name: lightDocument.name + ' copy',
                //       participantId: lightDocument.participantId,
                //       createdBy: lightDocument.createdBy,
                //     });
                //     history.push(`/notes/${newDoc.id}`);
                //   },
                // },
                {
                  icon: <TrashIcon className="mr-3 h-5 w-5" />,
                  name: 'Delete',
                  handler: async () => {
                    if (
                      confirm(
                        `Are you sure you want to delete "${recording.name}"?. This cannot be undone.`
                      )
                    ) {
                      deleteAudioRecording(recording.id);
                    }
                  },
                },
              ]
            : [],
        }))
      );
    }
  }, [loadingAudioRecordings, audioRecordings]);

  return (
    <div className="flex h-full justify-center">
      <Container>
        {org?.trialStatus === 'active' && <HomeTrialBanner />}

        <SecondaryToolbar>
          <div className="flex w-full flex-row py-7 justify-between items-center">
            <div>
              <h2 className={'text-md font-medium mt-2'}>Recent Projects</h2>
            </div>
            {hasDashboards && <NewProjectButton />}
          </div>
        </SecondaryToolbar>
        {!hasDashboards && dashboardsQuery.loading && <Loader />}
        {!hasDashboards && !dashboardsQuery.loading && (
          <EmptyState>
            <HeroImage src={projectsEmptyImage} wide></HeroImage>
            <EmptyStateTitle>Create a new project</EmptyStateTitle>
            <EmptyStateText style={{ marginBottom: '15px' }}>
              No projects to show, yet. Recent projects updated in your workspace will show up here.
              Create a new project to get started.
            </EmptyStateText>
            <NewProjectButton />
          </EmptyState>
        )}
        {hasDashboards && (
          <ProjectsList dashboards={dashboards} loading={dashboardsQuery.loading} folders={null} />
        )}

        {!loadingLightDocs &&
          !loadingAudioRecordings &&
          (!!lightDocsItems.length || !!audioRecordingItems.length) && (
            <SecondaryToolbar>
              <div className="flex w-full flex-row py-7 items-center">
                <h2 className={'text-md font-medium'}>Drafts</h2>
                <div className="flex items-center">
                  <div className="ml-4 mr-2">
                    <Icon24.EyeCrossed />
                  </div>
                  <div className="opacity-40">Only you can see this</div>
                </div>
              </div>
            </SecondaryToolbar>
          )}

        {(loadingLightDocs || loadingAudioRecordings) && <Loader />}
        {(!!lightDocsItems.length || audioRecordingItems.length) && (
          <ListWithFolders
            defaultItemTitle="document"
            items={[...(audioRecordingItems || []), ...(lightDocsItems || [])]}
            folders={null}
            onItemClick={(item) =>
              history.push(`/${item.itemType === 'document' ? 'notes' : 'recordings'}/${item.id}`)
            }
            setItemFolder={() => null}
          />
        )}

        <SecondaryToolbar>
          <div className="flex w-full flex-row pt-7 justify-between items-center">
            <div>
              <h2 className={'text-md font-medium mt-2'}>New Insights</h2>
            </div>
          </div>
        </SecondaryToolbar>
        {hasInsights && (
          <InsightsCardView insights={recentInsightsQuery.data || []} ignoreMarginX={true} />
        )}
        {!hasInsights && recentInsightsQuery.loading && <Loader />}
        {!hasInsights && !recentInsightsQuery.loading && (
          <EmptyState>
            <HeroImage src={insightsEmptyImage}></HeroImage>
            <EmptyStateTitle>New insights show up here</EmptyStateTitle>
            <EmptyStateText>
              <p>Tag and theme at least one project to generate insights with AI.</p>
            </EmptyStateText>

            <EmptyStateSecondaryText>
              <InsightsEmptyStateLink
                href="https://help.notably.ai/en/articles/8857034-what-is-an-insight"
                target="_blank"
              >
                Learn how
              </InsightsEmptyStateLink>
            </EmptyStateSecondaryText>
          </EmptyState>
        )}
      </Container>
    </div>
  );
}
