import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Icon24 } from '../../Icons/Icon';
import { default as lightbulbIcon } from '../../assets/projects/default-icons/Idea.svg';
import {
  TrashIcon,
  ArrowUpOnSquareIcon,
  MicrophoneIcon as MicrofoneOutlineIcon,
} from '@heroicons-v2/react/24/outline';
import { StopIcon, MicrophoneIcon, PauseIcon } from '@heroicons-v2/react/24/solid';

import {
  BackButton,
  Breadcrumbs,
  BreadcrumbsIcon,
  BreadcrumbsItem,
  Container,
  Details,
  DetailsRow,
  DotsButton,
  EditorContainer,
  Header,
  Label,
  LeftSide,
  MenuPanel,
  MenuPanelItem,
  NameInput,
  ProjectIcon,
  RightSide,
  UserIcon,
  RecorderBar,
  RecorderBarContainer,
  PlayerBar,
} from './styles';
import ParticipantPicker from '../../Components/ParticipantPicker';
import usePermissions from '../../Hooks/usePermissions';

import { Loader } from '../../Components';
import Button from '../../Components/Button';
import ChooseDataModal from '../../Components/ChooseDataModal';
import { checkOrganizationLimits, useOrganization } from '../../Hooks/useOrganization';
import useAnalytics from '../../Hooks/useAnalytics';
import useBoards from '../../Hooks/useBoards';
import { useEffectOnce } from 'usehooks-ts';
import { getRecordingById, Recording, updateOrInsertRecording } from '../../Utils/audioDB';
import {
  useFetchAudioRecording,
  useUpdateAudioRecording,
  useDeleteAudioRecording,
} from '../../Hooks/useRecordings';
import Icon68 from '../../Icons/Icon/Icon68';
import Well from '../../Components/Well';

function isMobile() {
  const minWidth = 768;
  return window.innerWidth < minWidth || screen.width < minWidth;
}

function formatTime(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const pad = (num: number) => num.toString().padStart(2, '0');

  if (hours > 0) {
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  } else {
    return `${pad(minutes)}:${pad(seconds)}`;
  }
}

const mimeTypes = [
  'audio/mpeg',
  'audio/mp3',
  'audio/mp4',
  'audio/webm;codecs=opus',
  'audio/ogg;codecs=opus',
  'audio/webm',
  'audio/ogg',
  'audio/aac',
  'audio/x-m4a',
];

function getSupportedMimeTypes() {
  return mimeTypes.filter((mimeType) => (window as any).MediaRecorder.isTypeSupported(mimeType));
}

const AudioRecord: FC = () => {
  const { recordId } = useParams<{ recordId: string }>();
  const history = useHistory();
  const { canEditDocuments } = usePermissions();
  const [localRecording, setLocalRecording] = useState<Recording | null>(null);
  const [updateAudioRecording] = useUpdateAudioRecording();
  const [deleteAudioRecording] = useDeleteAudioRecording();

  const [duration, setDuration] = useState(0);

  const [loadingAudioRecording, audioRecording] = useFetchAudioRecording(recordId);
  // console.log('audioRecording', audioRecording);
  // console.log('localRecording', localRecording);
  const clientId = audioRecording?.clientId;

  const [isRecording, setIsRecording] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState<
    'init' | 'recording' | 'paused' | 'finished'
  >('init');
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationRef = useRef<number | null>(null);

  const [, org] = useOrganization();
  const { analytics } = useAnalytics();
  const { createBoard } = useBoards();

  const [isChooseDataModalOpen, setChooseDataModalOpen] = useState(false);
  const [isChooseDataPopupOpen, setChooseDataPopupOpen] = useState(false);
  const [showMenuPanel, setShowMenuPanel] = useState(false);
  const breadcrumbsRef = useRef<HTMLDivElement>(null);
  const dotButtonRef = useRef<HTMLDivElement>(null);

  const supportedTypes = getSupportedMimeTypes();
  const mimeType = supportedTypes[0];

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new (window as any).MediaRecorder(stream, {
      audioBitsPerSecond: 128000,
      mimeType: mimeType,
    });
    mediaRecorderRef.current = mediaRecorder;

    const audioContext = new AudioContext();
    audioContextRef.current = audioContext;
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    analyserRef.current = analyser;
    source.connect(analyser);

    drawWaveform();

    mediaRecorder.ondataavailable = (event: any) => {
      audioChunksRef.current.push(event.data);
      setDuration((prev) => prev + 1);
      // TODO: save 1 sec chunks to indexedDB
    };

    mediaRecorder.onstop = async () => {
      if (!localRecording) return;
      const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
      const url = URL.createObjectURL(audioBlob);
      await updateOrInsertRecording(clientId, { blob: audioBlob });
      audioChunksRef.current = [];
      setLocalRecording({ ...localRecording, blob: audioBlob });

      audioContext.close();

      stream.getTracks().forEach((track) => {
        track.stop();
      });

      if (animationRef.current) cancelAnimationFrame(animationRef.current);
      setRecordingStatus('finished');
      updateAudioRecording(recordId, { finished: true, duration, mimeType });
    };

    mediaRecorder.start(1000);
    setIsRecording(true);
    setRecordingStatus('recording');
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setIsRecording(false);
  };

  const pauseRecording = () => {
    mediaRecorderRef.current?.pause();
    setIsRecording(false);
    setRecordingStatus('paused');
  };
  const continueRecording = () => {
    mediaRecorderRef.current?.resume();
    setRecordingStatus('recording');
  };

  const drawWaveform = () => {
    if (!canvasRef.current || !analyserRef.current) return;

    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext('2d');
    const analyser = analyserRef.current;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      if (!canvasContext) return;
      analyser.getByteTimeDomainData(dataArray);

      canvasContext.fillStyle = '#fff';
      canvasContext.fillRect(0, 0, canvas.width, canvas.height);

      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = 'red';
      // canvasContext.strokeStyle = '#3B2651';
      canvasContext.beginPath();

      const sliceWidth = canvas.width / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          canvasContext.moveTo(x, y);
        } else {
          canvasContext.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasContext.lineTo(canvas.width, canvas.height / 2);
      canvasContext.stroke();

      animationRef.current = requestAnimationFrame(draw);
    };

    draw();
  };
  //

  useEffect(() => {
    const getRecording = async () => {
      let recording = await getRecordingById(clientId);
      if (!recording) {
        await updateOrInsertRecording(clientId, {});
        recording = await getRecordingById(clientId);
      }
      setLocalRecording(recording);

      if (audioRecording?.finished === true) {
        setRecordingStatus('finished');
      }
    };
    if (clientId && !localRecording) {
      getRecording();
    }
  }, [clientId, audioRecording]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (dotButtonRef.current && !dotButtonRef.current.contains(e.target as Node)) {
        e.stopPropagation();
        setShowMenuPanel(false);
      }
    };
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  async function updateName(newName: string) {
    await updateAudioRecording(recordId, { name: newName });
    if (!localRecording) return;
    await updateOrInsertRecording(clientId, { name: newName });
  }

  async function updateParticipant(participantId: string | null) {
    await updateAudioRecording(recordId, { participantId });
  }

  const handleChooseProject = async (project: any) => {
    // if (!lightDoc) return;
    // const newDoc = await createDocument(project.id, {
    //   name: lightDoc.name,
    //   content: lightDoc.content,
    //   participantId: lightDoc.participantId,
    // });
    // deleteLightDocument(lightDoc.id);
    // history.push(`/projects/${project.id}/data/docs/${newDoc.id}`);
  };

  const handleSaveToNewProject = async (projectName: string) => {
    // if (!lightDoc) return;
    // if (!checkOrganizationLimits(org, history, ['projects'])) {
    //   return;
    // }
    // const newProject = await createBoard(projectName);
    // const newDoc = await createDocument(newProject.id, {
    //   name: lightDoc.name,
    //   content: lightDoc.content,
    //   participantId: lightDoc.participantId,
    // });
    // deleteLightDocument(lightDoc.id);
    // analytics.sendEvent('CreateProject', { id: newProject.id });
    // history.push(`/projects/${newProject.id}/data/docs/${newDoc.id}`);
  };

  const handleCopyDocument = async () => {
    // const newDoc = await createLightDocument({
    //   content: lightDoc.content,
    //   name: lightDoc.name + ' copy',
    //   participantId: lightDoc.participantId,
    //   createdBy: lightDoc.createdBy,
    // });
    // history.push(`/notes/${newDoc.id}`);
  };

  const handleDeleteRecording = async () => {
    if (!confirm('Are you sure you want to delete this recording?')) return;
    // await deleteRecordingFromDB(recordId);
    await deleteAudioRecording(recordId);
    history.replace('/');
  };

  const getPopupPosition = () => {
    if (!breadcrumbsRef.current) return { left: 0, top: 0 };
    const { left, top, height } = breadcrumbsRef.current.getBoundingClientRect();
    return { left: left - 34, top: top + height + 5 };
  };

  if (!audioRecording || !localRecording) {
    return <Loader />;
  }

  return (
    <>
      <Container>
        <Header>
          <LeftSide>
            <BackButton onClick={() => history.goBack()}>
              <Icon24.ChevronBack />
            </BackButton>
            {!isMobile() && (
              <>
                <ProjectIcon src={lightbulbIcon} />
                <Breadcrumbs
                  ref={breadcrumbsRef}
                  onClick={() => setChooseDataPopupOpen(!isChooseDataPopupOpen)}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  <BreadcrumbsItem>
                    Save to project{' '}
                    <BreadcrumbsIcon>
                      <Icon24.ChevronDown />
                    </BreadcrumbsIcon>
                  </BreadcrumbsItem>
                  /<BreadcrumbsItem>{audioRecording?.name || 'New recording'}</BreadcrumbsItem>
                </Breadcrumbs>
              </>
            )}
          </LeftSide>
          <RightSide>
            <Button
              type="secondary"
              size="md"
              onClick={() =>
                history.push(`/projects/upload?recordingId=${audioRecording.clientId}`)
              }
            >
              Add to Project
            </Button>

            <DotsButton
              ref={dotButtonRef}
              onClick={() => {
                setShowMenuPanel(!showMenuPanel);
              }}
            >
              <Icon24.Dots />

              <MenuPanel show={showMenuPanel}>
                {/* <MenuPanelItem
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={handleCopyDocument}
                >
                  Copy Recording
                </MenuPanelItem> */}

                <MenuPanelItem
                  itemColor="red"
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={handleDeleteRecording}
                >
                  Delete
                </MenuPanelItem>
              </MenuPanel>
            </DotsButton>
          </RightSide>
        </Header>
        <EditorContainer>
          <div className="mb-5">
            <Well wellKey="audio-recorder-well">
              Recording in the browser is optimized for in-person research on a mobile device. Only
              in-room audio is recorded. For remote interviews, record using your video conferencing
              software.
            </Well>
          </div>
          <Details>
            <NameInput
              defaultValue={audioRecording?.name || ''}
              onBlur={(e) => updateName(e.target.value)}
              onKeyDown={(e) => e.code === 'Enter' && e.currentTarget.blur()}
              placeholder="New recording"
              autoFocus={!audioRecording?.name}
              readOnly={!canEditDocuments}
            />
            <DetailsRow>
              <UserIcon />
              <Label>Participant</Label>
              <ParticipantPicker
                participant={audioRecording?.participant}
                onChange={updateParticipant}
                readOnly={!canEditDocuments}
              />
            </DetailsRow>
          </Details>

          <div className="flex items-center justify-center mt-20">
            <div>
              {recordingStatus === 'finished' && (
                <>
                  <div className="flex justify-center items-center flex-col">
                    <Icon68.Posty />
                    <div className="font-medium text-md flex justify-center mt-5">
                      Recording complete
                    </div>
                    <div className="font-medium text-sm text-primary-purple-light-40 flex justify-center mt-3">
                      Add this recording to a project to analyze with AI.
                    </div>
                    <Button
                      type="primary"
                      size="md"
                      className="mt-5"
                      onClick={() =>
                        history.push(`/projects/upload?recordingId=${audioRecording.clientId}`)
                      }
                    >
                      Add to Project
                    </Button>
                  </div>
                </>
              )}
              {recordingStatus !== 'finished' && (
                <>
                  <div className="flex justify-center">
                    {/* <div
                  className={
                    recordingStatus === 'recording'
                      ? 'w-20 h-20 flex items-center justify-center'
                      : ''
                  }
                  style={{
                    backgroundColor:
                      recordingStatus !== 'recording' ? '#fff' : 'rgba(250, 182, 182, 0.25)',
                  }}
                > */}
                    <button
                      onClick={startRecording}
                      className="rounded-full w-10 h-10 flex items-center justify-center ml-2"
                      style={{
                        backgroundColor:
                          recordingStatus !== 'recording' ? '#EFEDF1' : 'rgba(250, 182, 182, 0.5)',
                      }}
                    >
                      {recordingStatus !== 'recording' ? (
                        <MicrofoneOutlineIcon className={'h-5 w-5 cursor-pointer'} />
                      ) : (
                        <MicrophoneIcon className={'h-5 w-5 cursor-pointer'} color="#EA4335" />
                      )}
                    </button>
                    {/* </div> */}
                  </div>
                  <div className="font-medium text-md text-primary-purple-light-40 flex justify-center mt-5">
                    {recordingStatus === 'init' && 'Tap to start recording.'}
                    {recordingStatus === 'paused' && 'Recording paused. Tap to resume'}
                    {recordingStatus === 'recording' && 'Recording in progress...'}
                  </div>
                  {recordingStatus === 'recording' && (
                    <div className="font-medium text-md text-primary-purple-light-40 flex justify-center mt-3 items-center text-center max-w-md">
                      This experience is optimized for in-person conversations. Place the device
                      close to all speakers for the best results.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </EditorContainer>

        {isChooseDataModalOpen && (
          <ChooseDataModal
            type="insight"
            onClose={() => setChooseDataModalOpen(false)}
            onChooseEntity={handleChooseProject}
            onCreateNewProject={handleSaveToNewProject}
            title="Save to project"
            subtitile="Find an existing project to save this document to or continue with a new project."
          />
        )}

        {isChooseDataPopupOpen && (
          <ChooseDataModal
            type="insight"
            onClose={() => setChooseDataPopupOpen(false)}
            onChooseEntity={handleChooseProject}
            onCreateNewProject={handleSaveToNewProject}
            subtitile="Save this document to a project for analysis."
            minimized
            position={getPopupPosition()}
          />
        )}
      </Container>
      <RecorderBarContainer>
        {recordingStatus !== 'finished' && (
          <RecorderBar>
            {recordingStatus === 'recording' ? (
              <button
                onClick={pauseRecording}
                className="rounded-full w-10 h-10 flex items-center justify-center mr-2 "
              >
                <PauseIcon className={'h-6 w-6 cursor-pointer'} />
              </button>
            ) : (
              <button
                onClick={recordingStatus === 'paused' ? continueRecording : startRecording}
                className="rounded-full w-10 h-10 flex items-center justify-center mr-2"
              >
                <MicrophoneIcon className={'h-6 w-6 cursor-pointer'} />
              </button>
            )}
            <div className="flex items-center">
              <div>
                <canvas ref={canvasRef} width={160} height={30} className=""></canvas>
              </div>
              <div className="font-medium text-md ml-3">{formatTime(duration)}</div>
            </div>
            <button
              onClick={stopRecording}
              className=" w-10 h-10 flex items-center justify-center ml-2 "
            >
              <StopIcon className={'h-6 w-6 cursor-pointer'} />
            </button>
          </RecorderBar>
        )}
        {recordingStatus === 'finished' && localRecording.blob && (
          <PlayerBar>
            <audio
              controls
              src={URL.createObjectURL(localRecording.blob)}
              className="flex-1 mr-2"
            ></audio>
          </PlayerBar>
        )}
      </RecorderBarContainer>
    </>
  );
};

export default AudioRecord;
